<template>
  <b-row>
    <b-col v-if="!showForm" cols="12">
      <FilterCompoents :tipo="TIPO_PAGAMENTO" @filtrarComponent="filtrar" />
    </b-col>
    <b-col cols="12">
      <b-card v-if="!showForm" title="Contas a Pagar">
        <div class="custom-search d-flex justify-content-start">
          <b-button variant="primary" @click="addNew()"> Incluir </b-button>
        </div>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('Search') }}</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="handleSearch"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="listaPagamentoRecebimentos"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="edit(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="remove(props.row)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="tableConfigs.size"
                  :options="[tableConfigs.size]"
                  class="mx-1"
                  @input="handleChangePage"
                />
                <span class="text-nowrap">
                  of {{ tableConfigs.totalPages }} entries
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="tableConfigs.totalElements"
                  :per-page="tableConfigs.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <FormPaymentReceiptAccount
        v-else
        :title-form="objectFormInsert.titleForm"
        :combo-payment="comboFormaPagamento"
        :combo-type="comboTipoPagamento"
        :combo-situation="comboSituacao"
        :combo-account="comboContas"
        :object-edit="pagamentoRecebimento"
        @cancelar="cancelar"
        @save="save"
      />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import FormPaymentReceiptAccount from '@/layouts/components/FormPaymentReceiptAccount.vue'
import {
  BRow,
  BCol,
  BCard,
  BFormSelect,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BButton
} from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'
import FilterCompoents from '@/layouts/components/FilterComponents.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import _ from 'lodash'

export default {
  name: 'ContasReceber',
  components: {
    BRow,
    BCol,
    FormPaymentReceiptAccount,
    FilterCompoents,
    VueGoodTable,
    BCard,
    BFormSelect,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BButton
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('combosModule', {
      comboFormaPagamento: (state) => state.comboFormaPagamento,
      comboTipoPagamento: (state) => state.comboTipoPagamentoPorTipo,
      comboContas: (state) => state.comboContas
    }),
    ...mapState('pagamentoRecebimentoFinancialModule', {
      listaPagamentoRecebimentos: (state) => state.listaPagamentoRecebimentos,
      pagamentoRecebimento: (state) => state.pagamentoRecebimento,
      comboSituacao: (state) => state.comboSituacao,
      TIPO_PAGAMENTO: (state) => state.TIPO_PAGAMENTO
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  data() {
    return {
      tableConfigs: {
        last: false,
        totalPages: 5,
        totalElements: 41,
        size: 10,
        number: 0
      },
      pageConfig: {
        page: 0,
        size: 10,
        sort: 'descricao',
        order: 'asc',
        tipo: 0
      },
      pageLength: 10,
      isLoading: false,
      totalRecords: 0,
      searchTerm: '',
      ehAssociado: false,
      showCpfAssociado: true,
      showForm: false,
      columns: [
        {
          label: 'Id',
          field: 'id',
          sortable: false
        },
        {
          label: 'Data Lançamento',
          field: 'dataLancamento',
          formatFn: this.formatDateGrid,
          sortable: false
        },
        {
          label: 'Nome',
          field: 'registro.nome',
          sortable: false
        },
        {
          label: 'Descrição',
          field: 'descricao',
          sortable: false
        },
        {
          label: 'Valor',
          field: 'valor',
          formatFn: this.formatCurrencyBrazil,
          sortable: false
        },
        {
          label: 'Data Vencimento',
          field: 'dataVencimento',
          formatFn: this.formatDateGrid,
          sortable: false
        },
        {
          label: 'Data Quitação',
          field: 'dataQuitacao',
          formatFn: this.formatDateGrid,
          sortable: false
        },
        {
          label: 'Situação',
          field: 'situacao',
          sortable: false
        },
        {
          label: this.$t('Action'),
          field: 'action',
          sortable: false
        }
      ],
      objectFormInsert: {
        titleForm: 'Cadastro de Contas a Pagar'
      },
      filter: {}
    }
  },
  async mounted() {
    this.isLoading = true
    const result = await this.$store.dispatch(
      'pagamentoRecebimentoFinancialModule/loadTable',
      this.pageConfig
    )
    this.pageConfig.page = result.data.pageable.pageNumber
    this.tableConfigs.last = result.data.last
    this.tableConfigs.totalPages = result.data.totalPages
    this.tableConfigs.totalElements = result.data.totalElements
    this.tableConfigs.size = result.data.size
    this.tableConfigs.number = result.data.number
    const locale = 'pt-br'
    const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const firstDayDate = firstDay.toLocaleDateString(locale)
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const lastDayDate = lastDay.toLocaleDateString(locale)
    this.filter = {
      tipo: this.pageConfig.tipo,
      dataInicio: firstDayDate,
      dataFinal: lastDayDate,
      situacao: -1,
      campo: ''
    }
    this.isLoading = false
  },
  methods: {
    handleSearch: _.debounce(async function (searching) {
      this.isLoading = false
      this.filter.campo = searching
      if (!this.filter.dataInicio) {
        this.filter.dataInicio = null
      }
      if (!this.filter.dataFinal) {
        this.filter.dataFinal = null
      }
      if (!this.filter.registroId) {
        this.filter.registroId = null
      }
      this.filtrar(this.filter)
      this.isLoading = false
    }, 500),
    pageConfigdefault() {
      this.pageConfig = {
        check: false,
        page: 0,
        size: 10,
        sort: 'descricao',
        order: 'asc'
      }
    },
    handleChangePage(page) {
      this.pageConfig.page = page
      const obj = {
        filter: this.filter,
        pageConfig: this.pageConfig
      }
      this.loadTable(obj)
    },
    onSortChange(params) {
      this.pageConfigdefault()
      this.pageConfig.sort = params[0].field
      this.pageConfig.order = params[0].type
      const obj = {
        filter: this.filter,
        pageConfig: this.pageConfig
      }
      this.loadTable(obj)
    },
    async filtrar(val) {
      this.filter = val
      this.isLoading = true
      val.tipo = this.TIPO_PAGAMENTO
      const obj = {
        filter: val,
        pageConfig: this.pageConfig
      }
      this.loadTable(obj)
    },
    async loadTable(obj) {
      const result = await this.$store.dispatch(
        'pagamentoRecebimentoFinancialModule/filterPayment',
        obj
      )
      this.pageConfig.page = result.data.pageable.pageNumber
      this.tableConfigs.last = result.data.last
      this.tableConfigs.totalPages = result.data.totalPages
      this.tableConfigs.totalElements = result.data.totalElements
      this.tableConfigs.size = result.data.size
      this.tableConfigs.number = result.data.number
      this.isLoading = false
    },
    async addNew() {
      await this.$store.dispatch('combosModule/loadComboFormaPagamento')
      await this.$store.dispatch(
        'combosModule/loadComboTipoPagamentoPorTipo',
        0
      )
      await this.$store.dispatch('combosModule/loadComboContas')
      this.showForm = true
    },
    async edit(data) {
      this.$router.push({ path: `editPaymentAccount/${data.id}` })
    },
    async cancelar() {
      this.showForm = false
    },
    prepareForSave(data) {
      const objectSave = JSON.parse(JSON.stringify(data))
      const obj = {
        id: objectSave.id,
        registro: objectSave.registro,
        descricao: objectSave.descricao,
        dataLancamento: objectSave.dataLancamento,
        dataVencimento: objectSave.dataVencimento,
        dataQuitacao: objectSave.dataQuitacao,
        valor: objectSave.valor,
        desconto: objectSave.desconto,
        juro: objectSave.juro,
        multa: objectSave.multa,
        valorTotal: objectSave.valor,
        valorQuitado: objectSave.valorQuitado,
        obs: objectSave.obs,
        tipo: {
          id: objectSave.tipo
        },
        formaPagamento: {
          id: objectSave.formaPagamento
        },
        conta: objectSave.conta
          ? {
              id: objectSave.conta
            }
          : null,
        situacao: objectSave.situacao,
        ativo: objectSave.ativo,
        qtoRepetido: objectSave.qtoRepetido,
        observacao: objectSave.observacao
      }
      return obj
    },
    async save(data) {
      if (!data.registro.id) {
        this.MensagemError('Digite um CPF cadastrado!')
        return
      }
      const objectSave = this.prepareForSave(data)
      this.$store
        .dispatch(
          'pagamentoRecebimentoFinancialModule/insertNewPagamentoRecebimento',
          objectSave
        )
        .then((response) => {
          if (response.response) {
            if (response.response.data.errors) {
              this.MensagemError(
                `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
                `${response.response.data.errors[0].message}`
              )
              this.showForm = true
              return
            }
            this.MensagemError(
              `Falha ao Inserir o campo ${response.response.data.errors}`,
              `${response.response.data.message}`
            )
            this.showForm = true
            return
          }
          this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
          this.cancelar()
        })
    },
    async remove(data) {
      this.$swal({
        title: 'Remover o registro?',
        text: 'Está ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('pagamentoRecebimentoFinancialModule/delete', data.id)
            .then((response) => {
              const locale = 'pt-br'
              const date = new Date()
              const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
              const firstDayDate = firstDay.toLocaleDateString(locale)
              const lastDay = new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                0
              )
              const lastDayDate = lastDay.toLocaleDateString(locale)
              this.filter = {
                tipo: this.TIPO_PAGAMENTO,
                dataInicio: firstDayDate,
                dataFinal: lastDayDate,
                situacao: -1,
                campo: ''
              }
              if (response.data.error) {
                this.MensagemError(
                  'Falha ao remover o registro',
                  response.data.message
                )
                return
              }
              this.showMessageSuccess(
                'Remover',
                'Registro removido com sucesso!'
              )
              this.pageConfig.sort = 'descricao'
              const obj = {
                filter: this.filter,
                pageConfig: this.pageConfig
              }
              this.loadTable(obj)
            })
            .catch((err) => {
              this.MensagemError('Falha ao remover o registro', err.message)
              this.pageConfig.sort = 'descricao'
              const obj = {
                filter: this.filter,
                pageConfig: this.pageConfig
              }
              this.loadTable(obj)
            })
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
